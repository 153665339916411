window.$(document).ready(function () {

  
window.$('.QuikLinks_cta').on('click', function(){
  window.$('.footer_link_row').slideToggle();
  });


  
//   $(".header_menu_inner a").click(function(e) {
//     e.preventDefault();
//     var aid = $(this).attr("href");
//     $('html,body').animate({scrollTop: $(aid).offset().top}, 100);
// });


setTimeout(function(){
  var element = document.querySelector(".app_page_submenu a.active");
  if(element){
  element.scrollIntoView({behavior: "smooth" , block: 'nearest', inline: "center"});   
  }     
}, 100);

  window.$('#menu_mbl_CTA').on('click', function () {
    window.$('#app_Mainheader').addClass('MenuOpen');

    window.$('.Close_menu_overlay').on('click', function () {
      window.$('#app_Mainheader').removeClass('MenuOpen');
    });
    window.$('#Close_menu_CTA').on('click', function () {
      window.$('#app_Mainheader').removeClass('MenuOpen');
    });
  });



  window.$('#menu_mbl_AfterLogin').on('click', function () {
    window.$('.page-wrapper-panel').addClass('MenuOpen');

    window.$('.Close_menu_overlay').on('click', function () {
      window.$('.page-wrapper-panel').removeClass('MenuOpen');
    });
    window.$('#Close_menu_CTA').on('click', function () {
      window.$('.page-wrapper-panel').removeClass('MenuOpen');
    });
  });

  //Dropdown Start
  window.$('.hasChild a').on('click', function () {
    // $(this).toggleClass('SubMenuOpen');
    window.$(this).parents('li').toggleClass('SubMenuOpen');
    window.$(this).siblings('.submenuApp').slideToggle();
  });

  window.$('.hasChild.active').addClass('SubMenuOpen');
  window.$('.hasChild.active').find('.submenuApp').slideDown();
//Dropdown End

  var backtoTop = window.$('#backtoTop');

  window.$(window).scroll(function () {
    if (window.$(window).scrollTop() > 800) {
      backtoTop.addClass('show');
    } else {
      backtoTop.removeClass('show');
    }
  });

  backtoTop.on('click', function (e) {
    e.preventDefault();
    window.$('html, body').animate({ scrollTop: 0 }, '800');
  });







});



// Filter open Start
function FilterOpenMbl() {
  const element = document.getElementById("page_have_filter");
  element.classList.add("filters_open");
}

function FilterCloseMbl() {
  var element = document.getElementById("page_have_filter");
  element.classList.remove("filters_open");
}
//  Search bar close End


// window.onscroll = function () { myFunction() };

// var header = document.getElementById("app_Mainheader");
// var sticky = header.offsetTop;

// function myFunction() {
//   if (window.pageYOffset > sticky) {
//     header.classList.add("sticky");
//   } else {
//     header.classList.remove("sticky");
//   }
// }

window.onscroll = function () { 
  var header = document.getElementById("app_Mainheader");
  if(header){
    myFunction(header)
  } 
};

 

function myFunction(header) {
 var sticky = header.offsetTop;
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}




// window.$('.gallery-slider').slick({
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 2000,
//   arrows: true,
//   dots: false,
//   responsive: [
//     {
//       breakpoint: 991,
//       settings: {
//         slidesToShow: 3,
//       }
//     },
//     {
//       breakpoint: 767,
//       settings: {
//         slidesToShow: 2,
//       }
//     }
//   ]
  
// });


// window.$('.gallery-slider').slickLightbox({
//   itemSelector        : 'a',
//   navigateByKeyboard  : true
// });

// $(".gallery-slider .slick-slide a").click(function(){
//   $("body").addClass("lightbox-hidden");
// });
// $(".gallery-slider .slick-lightbox-close").click(function(){
//   $("body").removeClass("lightbox-hidden");
// });

// $('.KSF_clients_slider').slick({
//   slidesToShow: 5,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 2000,
//   arrows: false,
//   dots: false,
//   loop: true,
//   responsive: [
//     {
//       breakpoint: 991,
//       settings: {
//         slidesToShow: 3,
//       }
//     },
//     {
//       breakpoint: 767,
//       settings: {
//         slidesToShow: 2,
//       }
//     }
//   ]
  
// });