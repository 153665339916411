import { toast } from "react-toastify"

export class ApiService {

    static config = {
        // url: 'https://a91c-103-59-75-224.ngrok-free.app/jiunge_main/'
        // url: 'https://abnocloud.com/safiri_v2/',
        url: 'https://safiri24.com/',
        // ksfLiveUrl: 'https://www.ksfchurch.com/web/',
        // ksfUatUrl: 'https://abnocloud.com/ksf_backend/'
        ksfUatUrl: 'https://www.ksfchurch.com/web/'
        // url : "http://192.168.1.7/jiunge_main/"
    }

    static async fetch({
        url,
        options = {},
        value
    }) {
        if (!options.method) {
            options.method = "POST"
        }

        if (!options.headers) {
            options.headers = {
                'Content-Type': 'application/json'
            }
        }
        if (options.body && options.method == "POST") {
            options.body = JSON.stringify(options.body)
        }

        if (!options.doNotPrependUrl) {
            if (!value) {
                url = ApiService.config.url + "api/" + url
            } else {
                url = ApiService.config.ksfUatUrl + 'api/' + url
            }
        }

        return fetch(url, options)
            .then(response => {
                if (response.status == 422) {
                    return response.json();
                }
                if (response.status == 401) {
                    return response.json();
                }
                if (response.ok) return response.json()
                else {
                    toast.error("Failed to process")
                    // console.log('Error')
                }
            })
            .catch(err => {
                toast.error("Failed to process")
            })
    }

    static getSermons(body = {}) {
        return ApiService.fetch({
            url: 'member/get-sermons',
            options: {
                body: body
            }
        })
    }

    static getEvents(body = {}) {
        return ApiService.fetch({
            url: 'member/get-event-list',
            options: {
                body: body
            }
        })
    }

    static getMemories(body = {}) {
        return ApiService.fetch({
            url: 'member/get-event-memories-list',
            options: {
                body: body
            }
        })
    }

    static submitContactForm(body = {}) {
        return ApiService.fetch({
            url: 'contact-store',
            options: {
                body: body
            },
            value: 1
        })
    }

    static becomeVolunteerForm(body = {}) {
        return ApiService.fetch({
            url: 'volunteer-store',
            options: {
                body: body
            },
            value: 1
        })
    }

    static getbusinessDetail(body = {}) {
        return ApiService.fetch({
            url: 'get-business-details',
            options: {
                body: body
            },
            value: 1
        })
    }
}