import React, { lazy, useEffect, useState } from 'react';
import $ from 'jquery';

import './vendor/bootstrap-5.3.0-alpha1-dist/css/bootstrap.min.css'
// import './vendor/fontawesome-free-6.1.1-web/css/all.min.css'
import './vendor/select2@4.1.0-rc.0/select2.min.css'
import './assets/css/style.css'
import "./assets/js/script.js"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import Loader from './Common/Loader.js';
import UrlNotMatch from './Helper/UrlNotMatch.js';

import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './Redux/UserSlice.js';
import { ApiService } from './Service/ApiService.js';

const Home = React.lazy(() => import('./Home/Home'))
const Aboutus = React.lazy(() => import('./AboutUs/Aboutus'))
const SectionFellowship = React.lazy(() => import('./SectionFellowship/Home'))
const Events = React.lazy(() => import('./Events/Home'))
const Ministries = React.lazy(() => import('./Ministries/Home'))
const Contactus = React.lazy(() => import('./ContactUs/Home'))
const BecomeVolunteer = React.lazy(() => import('./BecomeVolunteer/Home'))
const GiftShop = React.lazy(() => import('./GiftShop/Home'))
const Careers = React.lazy(() => import('./Careers/Home'))
const BecomeMember = React.lazy(() => import('./BecomeMember/Home'))

const HeavenGate = React.lazy(() => import('./HeavenGate/Home.js'))
const MbciMedia = React.lazy(() => import('./MbciMedia/Home.js'))

function App() {
  const dispatch = useDispatch()
  const [showRoutes, setShowRoutes] = useState(false)

  const getBusinessApi = () => {
    ApiService.getbusinessDetail({})
      .then((data) => {
        if (data?.status) {
          dispatch(setUser(data?.data))
          setShowRoutes(true)
        } else {
          setShowRoutes(true)
          // toast.error("Failed to process")
        }
      }).catch(err => {
        setShowRoutes(true)
      })
  }

  useEffect(() => {
    getBusinessApi()
  }, [])
  return (
    <div className="">
      <BrowserRouter basename={''}>
        <ToastContainer />
        <Suspense fallback={<Loader />}>
          {showRoutes ?
            <Routes>

              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<Aboutus />} />
              <Route path="/section-fellowship" element={<SectionFellowship />} />
              <Route path="/events" element={<Events />} />
              <Route path="/ministries" element={<Ministries />} />
              <Route path="/contact-us" element={<Contactus />} />
              <Route path="/become-a-volunteer" element={<BecomeVolunteer />} />
              <Route path="/gift-shop" element={<GiftShop />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/become-a-member" element={<BecomeMember />} />

              <Route path="/heavens-gate" element={<HeavenGate />} />
              <Route path="/mbci-media" element={<MbciMedia />} />

              <Route path="/404" element={<UrlNotMatch />} />
              <Route path="*" element={<Navigate replace to="/404" />} />

            </Routes>
            : <Loader />}
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
