export const loadState = (type, defaultState) => {
    try {
        const serializedState = localStorage.getItem('user')
        if (serializedState == null) {
            return defaultState
        }
        return JSON.parse(serializedState)
    }
    catch (err) {
        return null
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('user', serializedState);
        return state
    }
    catch (err) {
        return state;
    }
}