import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../LocalStorage/Localstorage";

export const initialUserState = loadState('user', {
    user: ''
})

const UserSlice = createSlice({
    name: 'user',
    initialState: initialUserState,

    reducers: {
        setUser(state, action) {
            const user = action.payload
            state.user = user || ''
        }
    }
})

export const { setUser } = UserSlice.actions

export default UserSlice;