import React from 'react';
const UrlNotMatch = () => {
    return (
        <>
            <div className="font-56px w-100 font-secondary-bold line-height-120">
                Not found
            </div>
            <div className='font-16px w-100 font-secondary-bold line-height-120'>
                The requested URL was not found on this server.
            </div>
        </>
    )
}
export default UrlNotMatch;