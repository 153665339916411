import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./UserSlice";
import { saveState } from "../LocalStorage/Localstorage";

const store = configureStore({
    reducer : {
        userData : UserSlice.reducer
    }
})

store.subscribe(() => {
    saveState(store.getState().userData)
})

export default store;